/** @format */

import { FC } from 'react';
import styled from '@emotion/styled';

import { mapColors } from '../../../../../../theme/mapColors';
import { Target } from '../../../../../../shared/Target';

//#region ::: PARTIALS

const Label = styled.label({
  fontSize: '14px',
  color: `${mapColors.fonts.fontPrimary}`,
  fontWeight: '600',
});

const LabelContainer = styled.label({
  marginTop: '17px',
  backgroundColor: 'white',
  borderTopLeftRadius: '10px ',
  borderTopRightRadius: '10px',
  width: '100%%',
  display: 'flex',
  border: `1px solid ${mapColors.backgrounds.borderPrimary}`,
  borderBottom: 'none',
  height: '44px',
  margin: '16px 16px 0 16px',
});

const MarginBox = styled.div({
  margin: '0 20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
});

//#endregion

interface Props {
  label: string;
  isDiscount?: boolean;
}

export const TitleBar: FC<Props> = ({ label, isDiscount }): JSX.Element => {
  return (
    <LabelContainer>
      <MarginBox>
        <Label>{label}</Label>
        {isDiscount && <Target />}
      </MarginBox>
    </LabelContainer>
  );
};
