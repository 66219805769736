/** @format */

import { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { GeneralInfo } from './GeneralInfo';

import { mapColors } from '../../theme/mapColors';
import { DataInfo } from './DataInfo';
import { Brand } from './Brand';
import { TotalPortFolioRebate } from './TotalPortfolioRebate';
// import { TotalProvided } from './TotalProvided';
import { Rebates } from './Rebates';
import { EL360 } from './EL360';
import { Credit } from './CreditStatus';
import { Doors } from './Doors';
import { Contact } from './Contact';
import { VSDataFooter } from './VSDataFooter';
import { GPNSales } from './GPNSales';
import { VSData } from './VSData';

//import srcLogo from '../../assets/icons/outline/LogoNavbar.svg';
// import srcLogo from '../../assets/images/logo.png';

import { useDispatch } from 'react-redux';
// import { sliceCustomer } from '../../@ducks/customer/slice';
import { apiCustomer } from '../../services/customer/api';
// import { useAuth0 } from '@auth0/auth0-react';
import { /* useNavigate, */ useNavigate, useParams } from 'react-router-dom';
import { ROUTE_SEARCH, ROUTE_HOME } from '../../constants/general';
import { loaderSlice } from '../../services/loader/slice';
import { apiRefresh } from '../../services/auth/api';
import { BrandPerformance } from './BrandPerformance';
import { apiRebateFromCustomer, apiRebatesPotential, apiTypeIDFromCustomer, apiVisionSourceSections, Performances, SectionItem } from '../../services/rebate/api';
import { clearStorage } from '../../utils/localStorageUtils';
import { MainHeader } from '../../shared/MainHeader';
import { GeneralInfoSmall } from './GeneralInfoSmall';
import { Savings } from './Savings';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { PrintInfo } from './PrintInfo';
import { UIIcon } from '../../shared/Icon';
import srcLogo from '../../assets/images/logo.png';
import { GeneralInfoCover } from './GeneralInfoCover';
import { RewardsRebates } from './RewardsRebates';
import { Loader } from '../../shared/Loader';
import { Selection, SelectionMode } from '../../shared/Selection';
import { BrandPrintable } from './Brand/printable';
// import { BrandPrintable } from './Brand/printable';

export const Box = styled.div({
  width: '100%',
  height: 'auto',
  backgroundColor: `${mapColors.backgrounds.borderTertiary}`,
  borderBottomLeftRadius: '10px',
  borderBottomRightRadius: '10px',
  display: 'flex',
  border: `1px solid ${mapColors.backgrounds.borderPrimary}`,
});

export const MarginBox = styled.div({
  margin: '25px',
});

export const Column = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const Row = styled.div({
  display: 'flex',
  alignItems: 'center',
});

export const RowJustified = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
});

/*
const CardsContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  width: '1248px',
  alignSelf: 'center',
});
*/

//#endregion

export const PageDashboard: FC = (): JSX.Element => {
  const lastUpdatePanelEnabled = false;
  const dispatch = useDispatch();

  const { customerId, typeId } = useParams();
  const navigate = useNavigate();

  // const [id, setId] = useState<any>(localStorage.getItem('id'));
  // const initialStateRole = JSON.parse(localStorage.getItem('role') as string);
  // const [role, setRole] = useState(initialStateRole);
  const refreshToken = localStorage.getItem('refreshToken');
  // const role = localStorage.getItem('role');

  const [customer, setCustomer]: any = useState(null);
  const [brandTrackingData, setBrandTrackingData]: any = useState(null);
  const [potential, setPotential]: any = useState(null);
  const [performances, setPerformances] = useState<Performances | null>(null);
  // const [typeIDs, setTypeIDs] = useState<Array<TypeID> | null>(null);
  // const [vsTypeIDs, setVSTypeIDs] = useState<VSTypeID | null>(null);
  const [vsSections, setVSSections] = useState<Array<SectionItem> | null>(null);
  const [printDate, setPrintDate] = useState(new Date().toLocaleDateString('en-US'));
  const [doorsOpened, setDoorsOpened] = useState(false);
  const [contactsOpened, setContactsOpened] = useState(false);
  const [isXR, setIsXR] = useState(false);
  const [level, setLevel] = useState('');
  // const [isCommonOwner, setIsCommonOwner] = useState(false);
  const [isMainAccount, setIsMainAccount] = useState(false);
  const isCustomer = localStorage.getItem('customerId') !== null && localStorage.getItem('customerId') !== 'null';
  const [isLoading, setIsLoading] = useState(true);
  const [printMode, setPrintMode] = useState(false);
  const [pdfFakeCounter, setPdfFakeCounter] = useState(1);
  const [pdfWorkaround, setPdfWorkaround] = useState(false);
  const [pdfExportEnabled, setPdfExportEnabled] = useState(true);
  const [pdfItemsBrands, setPdfItemsBrands] = useState(Array<any>());
  const [brandsArray, setBrandsArray] = useState(Array<any>());
  const [doneCount, setDoneCount] = useState(0);

  const lastUpdateDate = customer?.years?.data_updated_on
    ? new Date(customer.years.data_updated_on).toLocaleDateString('en-US', { timeZone: 'EST' }) + ' ' + new Date(customer.years.data_updated_on).toLocaleTimeString('en-US', { timeZone: 'EST' }) + ' EST/EDT'
    : null;
  const customerCode = customer?.customer_data?.customer_code ?? '';

  const updateInfoElement = document.getElementById('update-info');
  if (lastUpdateDate !== null) {
    if (updateInfoElement) {
      updateInfoElement.innerText = `Last data update: ${lastUpdateDate}`;
      updateInfoElement.style.display = 'flex';
    }
  } else {
    if (updateInfoElement) updateInfoElement.style.display = 'none';
  }

  const [pdfItems, setPdfItems] = useState([
    'account-information',
    'credit-status',
    'portfolio-rating',
    'total-portfolio-rebates',
    'el360',
    'el360panel',
    'doors',
    'contacts',
    'vsdata',
  ]);

  const [pdfItemsSavings, setPdfItemsSavings] = useState([
    new Date().getFullYear() - 1,
    new Date().getFullYear(),
  ]);

  const [pdfItemsRebates, setPdfItemsRebates] = useState([
    new Date().getFullYear() - 1,
    new Date().getFullYear(),
  ]);

  let accountInfoAlreadyAdded = false;
  let screenLock: any | null;
  const anyNav: any = navigator;

  function isScreenLockSupported() {
    return ('wakeLock' in navigator);
  }

  async function getScreenLock() {
    if (isScreenLockSupported()) {
      try {
        screenLock = await anyNav['wakeLock'].request('screen');
        console.log('Lock acquired 🎈');
      } catch (err: any) {
        console.log(err.name, err.message);
      }
      return screenLock;
    }
  }

  function releaseScreenLock() {
    if (typeof screenLock !== undefined && screenLock !== null) {
      screenLock.release()
        .then(() => {
          console.log('Lock released 🎈');
          screenLock = null;
        });
    }
  }

  function forceUpdate() {
    // throw new Error('Function not implemented.');
    let temp = pdfFakeCounter + 1;
    setPdfFakeCounter(temp++);
  }

  function checkPdfOptions() {
    setPdfExportEnabled(pdfItems.length + pdfItemsSavings.length + pdfItemsRebates.length + pdfItemsBrands.length > 0);
  }

  function togglePdfItem(item: any) {
    const temp = pdfItems;
    if (temp.includes(item)) {
      temp.splice(temp.indexOf(item, 0), 1);
    } else {
      temp.push(item);
    }
    setPdfItems(temp);
    checkPdfOptions();
    forceUpdate();
  }

  function removePdfItem(item: any) {
    const temp = pdfItems;
    if (temp.includes(item)) {
      temp.splice(temp.indexOf(item, 0), 1);
      setPdfItems(temp);
      checkPdfOptions();
      forceUpdate();
    }
  }

  function togglePdfItemSavings(item: any) {
    const temp = pdfItemsSavings;
    if (temp.includes(item)) {
      temp.splice(temp.indexOf(item, 0), 1);
    } else {
      temp.push(item);
    }
    setPdfItemsSavings(temp);
    checkPdfOptions();
    forceUpdate();
  }

  function togglePdfItemRebates(item: any) {
    const temp = pdfItemsRebates;
    if (temp.includes(item)) {
      temp.splice(temp.indexOf(item, 0), 1);
    } else {
      temp.push(item);
    }
    setPdfItemsRebates(temp);
    checkPdfOptions();
    forceUpdate();
  }

  async function cropVertically(base64String: string, startY: number, endY: number, availableWidth: number): Promise<string> {
    const img = new Image();
    img.src = base64String;

    return new Promise(resolve => {
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        if (context !== null) {
          const correction = img.width / availableWidth;
          const pixelRatio = window.devicePixelRatio;
          const scaleX = img.naturalWidth / img.width;
          const scaleY = img.naturalHeight / img.height;
          canvas.width = img.width * pixelRatio;
          canvas.height = (endY - startY) * correction * pixelRatio;
          context.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
          context.imageSmoothingQuality = 'high';
          context.drawImage(
            img,
            0 * scaleX,
            startY * correction * scaleY,
            canvas.width * scaleX,
            canvas.height * scaleY,
            0,
            0,
            canvas.width,
            canvas.height,
          );
          const check = canvas.toDataURL();
          resolve(check);
        } else {
          resolve(base64String);
        }
      };
    });
  }

  function checkExpanded(id: string) {
    const targetElement = document.querySelector('#' + id + ' .expandable');
    if (targetElement && targetElement.classList.contains('expanded')) return true;
    return false;
  }

  async function expand(id: string) {
    const targetElement = document.querySelector('#' + id + ' .expandable');
    if (targetElement && !targetElement.classList.contains('expanded')) {
      document.querySelector('#' + id + ' .head')?.dispatchEvent(new MouseEvent('click', {
        'view': window,
        'bubbles': true,
        'cancelable': false,
      }));
      await new Promise(r => setTimeout(r, 1000));
    }
  }

  function collapse(id: string) {
    const targetElement = document.querySelector('#' + id + ' .expandable');
    if (targetElement && targetElement.classList.contains('expanded')) {
      document.querySelector('#' + id + ' .head')?.dispatchEvent(new MouseEvent('click', {
        'view': window,
        'bubbles': true,
        'cancelable': false,
      }));
    }
  }

  const printHeaderImageData = {
    data: '',
    width: 0,
    height: 0,
    ratio: 0,
  };

  const accountInfoHeaderImageData = {
    data: '',
    width: 0,
    height: 0,
    ratio: 0,
  };

  async function preloadPrintHeader(pdf: any, horizontalMargin: any) {
    const printInfoElement = document.getElementById('print-info');
    if (printInfoElement !== null) {
      await html2canvas(printInfoElement, {
        logging: false,
      }).then((canvas) => {
        const pdfWidth = pdf.internal.pageSize.getWidth();
        printHeaderImageData.data = canvas.toDataURL('image/svg');
        printHeaderImageData.width = canvas.width;
        printHeaderImageData.height = canvas.height;
        printHeaderImageData.ratio = Math.min((pdfWidth - (horizontalMargin * 2)) / printHeaderImageData.width);
      });
    }
    return true;
  }

  async function preloadAccountInfoHeader(pdf: any, horizontalMargin: any) {
    const accountInfoContainerElement = document.getElementById('account-information-container');
    if (accountInfoContainerElement !== null) {
      await html2canvas(accountInfoContainerElement, {
        logging: false,
      }).then((canvas) => {
        const pdfWidth = pdf.internal.pageSize.getWidth();
        accountInfoHeaderImageData.data = canvas.toDataURL('image/svg');
        accountInfoHeaderImageData.width = canvas.width;
        accountInfoHeaderImageData.height = canvas.height;
        accountInfoHeaderImageData.ratio = Math.min((pdfWidth - (horizontalMargin * 2)) / accountInfoHeaderImageData.width);
      });
    }
    return true;
  }

  function addPrintHeader(pdf: any, y: any, verticalSpacing: any) {
    if (printHeaderImageData.data !== '') {
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const x = (pdfWidth - printHeaderImageData.width * printHeaderImageData.ratio) / 2;
      pdf.addImage(printHeaderImageData.data, 'PNG', x, y, printHeaderImageData.width * printHeaderImageData.ratio, printHeaderImageData.height * printHeaderImageData.ratio);
      y += (printHeaderImageData.height * printHeaderImageData.ratio) + verticalSpacing;
    }
    return y;
  }

  function addAccountInfoHeader(pdf: any, y: any, verticalSpacing: any) {
    if (accountInfoHeaderImageData.data !== '') {
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const x = (pdfWidth - accountInfoHeaderImageData.width * accountInfoHeaderImageData.ratio) / 2;
      pdf.addImage(accountInfoHeaderImageData.data, 'PNG', x, y, accountInfoHeaderImageData.width * accountInfoHeaderImageData.ratio, accountInfoHeaderImageData.height * accountInfoHeaderImageData.ratio);
      y += (accountInfoHeaderImageData.height * accountInfoHeaderImageData.ratio) + verticalSpacing;
    }
    return y;
  }

  async function addElement(element: any, pdf: any, y: any, horizontalMargin: any, verticalMargin: any, verticalSpacing: any) {
    // console.log('Processing', element);
    await html2canvas(element, {
      logging: false,
    }).then(async (canvas) => {
      const imgData = canvas.toDataURL('image/svg');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight() - (verticalMargin * 2);
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min((pdfWidth - (horizontalMargin * 2)) / imgWidth);
      const targetWidth = imgWidth * ratio;
      const targetHeight = imgHeight * ratio;

      const x = (pdfWidth - targetWidth) / 2;

      if (targetHeight > pdfHeight - verticalMargin - y) { // Greater than available space
        // console.log('Required element is greater than available space', targetHeight, pdfHeight - (verticalMargin * 2) - y);
        pdf.addPage();
        y = verticalMargin;
        y = addPrintHeader(pdf, y, 2);
        y = addAccountInfoHeader(pdf, y, 2);

        if (targetHeight > pdfHeight - verticalMargin - y) { // Greater than entire page (including headers)
          const availableHeight = pdfHeight - verticalMargin - y;
          // console.log('Required element is greater than entire page - Splitting', targetHeight, availableHeight);
          const requiredPages = Math.ceil(targetHeight / availableHeight);
          // console.log('Required pages', requiredPages, targetHeight, availableHeight);
          for (let i = 1; i <= requiredPages; i++) {
            const croppedImage = await cropVertically(imgData, (i - 1) * availableHeight, i * availableHeight, pdfWidth - (horizontalMargin * 2));
            await pdf.addImage(croppedImage, 'PNG', x, y, imgWidth * ratio, availableHeight);
            y += availableHeight;
            if (i < requiredPages) {
              pdf.addPage();
              y = verticalMargin;
              y = addPrintHeader(pdf, y, 2);
              y = addAccountInfoHeader(pdf, y, 2);
            }
          }
        } else { // Append normally */
          await pdf.addImage(imgData, 'PNG', x, y, imgWidth * ratio, imgHeight * ratio);
          y += (imgHeight * ratio) + verticalSpacing;
        }
      } else {

        if (!accountInfoAlreadyAdded) {
          y = addAccountInfoHeader(pdf, y, 2);
          accountInfoAlreadyAdded = true;
        }

        await pdf.addImage(imgData, 'PNG', x, y, imgWidth * ratio, imgHeight * ratio);
        y += (imgHeight * ratio) + verticalSpacing;
      }
    });
    await new Promise(r => setTimeout(r, 250));
    return y;
  }

  function countTotalOperations() {
    let totalOperations = 4;
    if (pdfItems.includes('account-information')) totalOperations++;
    if (pdfItems.includes('credit-status')) totalOperations++;
    if (pdfItems.includes('portfolio-rating')) totalOperations++;
    if (pdfItemsSavings.length > 0) {
      if (pdfItemsSavings.includes(new Date().getFullYear() - 1)) totalOperations++;
      if (pdfItemsSavings.includes(new Date().getFullYear())) totalOperations++;
    }
    if (pdfItemsRebates.length > 0) {
      if (pdfItemsRebates.includes(new Date().getFullYear() - 1)) totalOperations++;
      if (pdfItemsRebates.includes(new Date().getFullYear())) totalOperations++;
    }
    if (pdfItems.includes('vsdata')) totalOperations++;
    if (pdfItems.includes('total-portfolio-rebates')) totalOperations++;
    if (pdfItemsBrands.length > 0) totalOperations += pdfItemsBrands.length;
    if (pdfItems.includes('el360')) totalOperations++;
    if (pdfItems.includes('el360panel')) totalOperations++;
    if (pdfItems.includes('doors')) totalOperations++;
    if (pdfItems.includes('contacts')) totalOperations++;
    return totalOperations;
  }

  async function toPdf() {

    await getScreenLock();

    if (isXR || customerCode === '0010015790' || customerCode === '0010023230') {
      setPdfItemsBrands([]);
    }

    accountInfoAlreadyAdded = false;
    let operationsDoneCounter = 0;

    setDoneCount(operationsDoneCounter);
    setPrintDate(new Date().toLocaleDateString('en-US'));

    document.body.classList.add('print');

    setPrintMode(true);

    const pdf = new jsPDF('p', 'mm', undefined, true);
    // const toBePrint = AvailablePrintSections.filter(e => e.enabled === true);

    const horizontalMargin = 12;
    const verticalMargin = 8;
    const verticalSpacing = 4;

    await preloadPrintHeader(pdf, horizontalMargin);
    setDoneCount(operationsDoneCounter++);
    await preloadAccountInfoHeader(pdf, horizontalMargin);
    setDoneCount(operationsDoneCounter++);

    let y = verticalMargin;

    /* COMPOSE COVER */
    y = addPrintHeader(pdf, y, 0);
    y += 50;

    const coverElement = document.getElementById('cover');
    if (coverElement !== null) {
      await html2canvas(coverElement, {
        logging: false,
      }).then(async (canvas) => {
        const imgData = canvas.toDataURL('image/svg');

        const pdfWidth = pdf.internal.pageSize.getWidth();
        // const pdfHeight = pdf.internal.pageSize.getHeight();

        const imgWidth = canvas.width;
        const imgHeight = canvas.height;

        const ratio = Math.min((pdfWidth - (horizontalMargin * 2)) / imgWidth) * 1.5;

        const x = (pdfWidth - imgWidth * ratio) / 2;
        pdf.addImage(imgData, 'PNG', x, y, imgWidth * ratio, imgHeight * ratio);

        y += (imgHeight * ratio) + verticalSpacing;
      });
      setDoneCount(operationsDoneCounter++);
    }

    const accountInfoElement = document.getElementById('account-information-cover');
    if (accountInfoElement !== null) {
      await html2canvas(accountInfoElement, {
        logging: false,
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/svg');

        const pdfWidth = pdf.internal.pageSize.getWidth();
        // const pdfHeight = pdf.internal.pageSize.getHeight();

        const imgWidth = canvas.width;
        const imgHeight = canvas.height;

        const ratio = Math.min((pdfWidth - (horizontalMargin * 2)) / imgWidth) * 0.4;

        const x = (pdfWidth - imgWidth * ratio) / 2;
        pdf.addImage(imgData, 'PNG', x, y, imgWidth * ratio, imgHeight * ratio);

        y += (imgHeight * ratio) + verticalSpacing;
      });
      setDoneCount(operationsDoneCounter++);
    }

    // Add page
    pdf.addPage();
    y = verticalMargin;
    y = addPrintHeader(pdf, y, 2);

    let shouldCreateNewPage = false;

    // COMPOSE ACCOUNT INFORMATION
    if (pdfItems.includes('account-information')) {
      const currentSectionId = 'account-information';
      const accountInformationSection = document.getElementById(currentSectionId);
      if (accountInformationSection !== null) {
        const isExpanded = checkExpanded(currentSectionId);
        if (!isExpanded) await expand(currentSectionId);
        accountInfoAlreadyAdded = true;
        y = await addElement(accountInformationSection, pdf, y, horizontalMargin, verticalMargin, verticalSpacing);
        if (!isExpanded) collapse(currentSectionId);
      }
      setDoneCount(operationsDoneCounter++);
      shouldCreateNewPage = true;
    } else {
      y = addAccountInfoHeader(pdf, y, 2);
      accountInfoAlreadyAdded = true;
    }

    // COMPOSE ACCOUNT INFORMATION
    if (pdfItems.includes('credit-status')) {
      const currentSectionId = 'credit-status';
      const creditStatusSection = document.getElementById(currentSectionId);
      if (creditStatusSection !== null) {
        const isExpanded = checkExpanded(currentSectionId);
        if (!isExpanded) await expand(currentSectionId);
        y = await addElement(creditStatusSection, pdf, y, horizontalMargin, verticalMargin, verticalSpacing);
        if (!isExpanded) collapse(currentSectionId);
      }
      setDoneCount(operationsDoneCounter++);
      shouldCreateNewPage = true;
    }

    // COMPOSE PORTFOLIO RATING
    if (pdfItems.includes('portfolio-rating')) {
      const currentSectionId = 'portfolio-rating';
      const portfolioRatingSection = document.getElementById(currentSectionId);
      if (portfolioRatingSection !== null) {
        const isExpanded = checkExpanded(currentSectionId);
        if (!isExpanded) await expand(currentSectionId);
        y = await addElement(portfolioRatingSection, pdf, y, horizontalMargin, verticalMargin, verticalSpacing);
        if (!isExpanded) collapse(currentSectionId);
      }
      setDoneCount(operationsDoneCounter++);
      shouldCreateNewPage = true;
    }


    // }
    // COMPOSE SAVINGS
    if (pdfItemsSavings.length > 0) { // }.includes('savings')) {
      const currentSectionId = 'savings';
      const savingsSection = document.getElementById(currentSectionId);
      if (savingsSection !== null) {
        const isExpanded = checkExpanded(currentSectionId);
        if (!isExpanded) await expand(currentSectionId);

        await new Promise(r => setTimeout(r, 750));

        if (pdfItemsSavings.includes(new Date().getFullYear() - 1)) {
          document.querySelector('#' + currentSectionId + ' .selector .previous-year')?.dispatchEvent(new MouseEvent('click', {
            'view': window,
            'bubbles': true,
            'cancelable': false,
          }));
          await new Promise(r => setTimeout(r, 750));
          y = await addElement(savingsSection, pdf, y, horizontalMargin, verticalMargin, verticalSpacing);
          setDoneCount(operationsDoneCounter++);
          shouldCreateNewPage = true;
        }

        if (pdfItemsSavings.includes(new Date().getFullYear())) {
          document.querySelector('#' + currentSectionId + ' .selector .current-year')?.dispatchEvent(new MouseEvent('click', {
            'view': window,
            'bubbles': true,
            'cancelable': false,
          }));
          await new Promise(r => setTimeout(r, 750));
          y = await addElement(savingsSection, pdf, y, horizontalMargin, verticalMargin, verticalSpacing);
          setDoneCount(operationsDoneCounter++);
          shouldCreateNewPage = true;
        }

        if (!isExpanded) collapse(currentSectionId);
      }
    }

    // COMPOSE REBATES
    if (pdfItemsRebates.length > 0) { // pdfItems.includes('rewards-rebates')) {
      const currentSectionId = 'rewards-rebates';
      const rebatesSection = document.getElementById(currentSectionId);
      if (rebatesSection !== null) {
        const isExpanded = checkExpanded(currentSectionId);
        if (!isExpanded) await expand(currentSectionId);

        await new Promise(r => setTimeout(r, 750));

        if (pdfItemsRebates.includes(new Date().getFullYear() - 1)) {

          document.querySelector('#' + currentSectionId + ' .selector .previous-year')?.dispatchEvent(new MouseEvent('click', {
            'view': window,
            'bubbles': true,
            'cancelable': false,
          }));

          await new Promise(r => setTimeout(r, 750));

          y = await addElement(rebatesSection, pdf, y, horizontalMargin, verticalMargin, verticalSpacing);
          setDoneCount(operationsDoneCounter++);
          shouldCreateNewPage = true;
        }

        if (pdfItemsRebates.includes(new Date().getFullYear())) {
          document.querySelector('#' + currentSectionId + ' .selector .current-year')?.dispatchEvent(new MouseEvent('click', {
            'view': window,
            'bubbles': true,
            'cancelable': false,
          }));

          await new Promise(r => setTimeout(r, 750));

          y = await addElement(rebatesSection, pdf, y, horizontalMargin, verticalMargin, verticalSpacing);
          setDoneCount(operationsDoneCounter++);
          shouldCreateNewPage = true;
        }

        if (!isExpanded) collapse(currentSectionId);
      }
    }

    // COMPOSE VISION SOURCE DATA
    if (pdfItems.includes('vsdata')) {
      const currentSectionId = 'vsdata';
      const vsDataSection = document.getElementById(currentSectionId);
      if (vsDataSection !== null) {
        const isExpanded = checkExpanded(currentSectionId);
        if (!isExpanded) await expand(currentSectionId);

        let headAlreadyPrinted = false;
        const quarterPanel = document.querySelector('#vsdata .quarter');
        const semesterPanel = document.querySelector('#vsdata .semester');
        const annualPanel = document.querySelector('#vsdata .annual');

        if (quarterPanel) {
          vsDataSection.classList.add('print-quarter');
          vsDataSection.classList.add('no-bottom-border');
          if (headAlreadyPrinted) {
            vsDataSection.classList.add('no-head');
            vsDataSection.classList.add('no-top-border');
          } else {
            headAlreadyPrinted = true;
          }
          y = await addElement(vsDataSection, pdf, y, horizontalMargin, verticalMargin, 0);
          vsDataSection.classList.remove('print-quarter');
          vsDataSection.classList.remove('no-bottom-border');
          vsDataSection.classList.remove('no-top-border');
          vsDataSection.classList.remove('no-head');
        }

        if (semesterPanel) {
          vsDataSection.classList.add('print-semester');
          vsDataSection.classList.add('no-bottom-border');
          if (headAlreadyPrinted) {
            vsDataSection.classList.add('no-head');
            vsDataSection.classList.add('no-top-border');
          } else {
            headAlreadyPrinted = true;
          }
          y = await addElement(vsDataSection, pdf, y, horizontalMargin, verticalMargin, 0);
          vsDataSection.classList.remove('print-semester');
          vsDataSection.classList.remove('no-bottom-border');
          vsDataSection.classList.remove('no-top-border');
          vsDataSection.classList.remove('no-head');
        }

        if (annualPanel) {
          vsDataSection.classList.add('print-annual');
          if (headAlreadyPrinted) {
            vsDataSection.classList.add('no-head');
            vsDataSection.classList.add('no-top-border');
          } else {
            headAlreadyPrinted = true;
          }
          y = await addElement(vsDataSection, pdf, y, horizontalMargin, verticalMargin, 0);
          vsDataSection.classList.remove('print-annual');
          vsDataSection.classList.remove('no-top-border');
          vsDataSection.classList.remove('no-bottom-border');
          vsDataSection.classList.remove('no-head');
        }


        /*
        y = await addElement(vsDataSection, pdf, y, horizontalMargin, verticalMargin, verticalSpacing);
        if (!isExpanded) collapse(currentSectionId);
        */
      }
      setDoneCount(operationsDoneCounter++);
      shouldCreateNewPage = true;
    }

    // COMPOSE PORTFOLIO REBATES
    if (pdfItems.includes('total-portfolio-rebates')) {
      const currentSectionId = 'total-portfolio-rebates';
      const totalPortfolioRebatesSection = document.getElementById(currentSectionId);
      if (totalPortfolioRebatesSection !== null) {
        const isExpanded = checkExpanded(currentSectionId);
        if (!isExpanded) await expand(currentSectionId);
        y = await addElement(totalPortfolioRebatesSection, pdf, y, horizontalMargin, verticalMargin, verticalSpacing);
        if (!isExpanded) collapse(currentSectionId);
      }
      setDoneCount(operationsDoneCounter++);
      shouldCreateNewPage = true;
    }

    let hasBrandData = false;

    // COMPOSE BRANDS
    if (pdfItemsBrands.length > 0) {
      const brandPanels = document.querySelectorAll('[data-brand]');
      if (brandPanels) {
        let firstPanel = true;
        for (let i = 0; i < brandPanels.length; i++) {

          hasBrandData = true;
          const panel: any = brandPanels[i];
          if (panel.dataset.brand > 0) {
            if (shouldCreateNewPage || !firstPanel) {
              pdf.addPage();
              y = verticalMargin;
              y = addPrintHeader(pdf, y, 2);
              y = addAccountInfoHeader(pdf, y, 2);
            }

            firstPanel = false;

            // First box
            panel.classList.add('current-print-0');
            y = await addElement(panel, pdf, y, horizontalMargin, verticalMargin, 0);
            panel.classList.remove('current-print-0');

            // Other boxex
            panel.classList.add('current-print');

            const boxes = panel.querySelectorAll('.brand-rebate-box');
            for (let j = 0; j < boxes.length; j++) {
              const isLast = j === boxes.length - 1;
              if (isLast) panel.classList.add('last');
              boxes[j].classList.add('current-rebate-box');
              y = await addElement(panel, pdf, y, horizontalMargin, verticalMargin, isLast ? verticalSpacing : 0);
              boxes[j].classList.remove('current-rebate-box');
            }
            panel.classList.remove('current-print');
            panel.classList.remove('last');
          }
          setDoneCount(operationsDoneCounter++);
        }
      }
    }

    // COMPOSE EL360
    if (pdfItems.includes('el360')) {
      const currentSectionId = 'el360';
      const el360Section = document.getElementById(currentSectionId);
      if (el360Section !== null) {

        if (hasBrandData) {
          hasBrandData = false;
          pdf.addPage();
          y = verticalMargin;
          y = addPrintHeader(pdf, y, 2);
          y = addAccountInfoHeader(pdf, y, 2);
        }

        const isExpanded = checkExpanded(currentSectionId);
        if (!isExpanded) await expand(currentSectionId);
        await new Promise(r => setTimeout(r, 750)); // Waits for graph rendering
        y = await addElement(el360Section, pdf, y, horizontalMargin, verticalMargin, verticalSpacing);
        if (!isExpanded) collapse(currentSectionId);

      }
      setDoneCount(operationsDoneCounter++);
    }

    // COMPOSE BRAND DATA
    if (pdfItems.includes('el360panel')) {
      const currentSectionId = 'el360panel';
      const el360PanelSection = document.getElementById(currentSectionId);
      if (el360PanelSection !== null) {

        if (hasBrandData) {
          hasBrandData = false;
          pdf.addPage();
          y = verticalMargin;
          y = addPrintHeader(pdf, y, 2);
          y = addAccountInfoHeader(pdf, y, 2);
        }

        const isExpanded = checkExpanded(currentSectionId);
        if (!isExpanded) await expand(currentSectionId);
        y = await addElement(el360PanelSection, pdf, y, horizontalMargin, verticalMargin, verticalSpacing);
        if (!isExpanded) collapse(currentSectionId);

      }
      setDoneCount(operationsDoneCounter++);
    }

    // COMPOSE BRAND DATA
    if (pdfItems.includes('doors')) {
      const currentSectionId = 'doors';
      const doorsSection = document.getElementById(currentSectionId);
      if (doorsSection !== null) {

        if (hasBrandData) {
          hasBrandData = false;
          pdf.addPage();
          y = verticalMargin;
          y = addPrintHeader(pdf, y, 2);
          y = addAccountInfoHeader(pdf, y, 2);
        }

        const isExpanded = checkExpanded(currentSectionId);
        if (!isExpanded) await expand(currentSectionId);
        y = await addElement(doorsSection, pdf, y, horizontalMargin, verticalMargin, verticalSpacing);
        if (!isExpanded) collapse(currentSectionId);

      }
      setDoneCount(operationsDoneCounter++);
    }

    // COMPOSE CONTACTS
    if (pdfItems.includes('contacts')) {
      const currentSectionId = 'contacts';
      const contactsSection = document.getElementById(currentSectionId);
      if (contactsSection !== null) {

        if (hasBrandData) {
          hasBrandData = false;
          pdf.addPage();
          y = verticalMargin;
          y = addPrintHeader(pdf, y, 2);
          y = addAccountInfoHeader(pdf, y, 2);
        }

        const isExpanded = checkExpanded(currentSectionId);
        if (!isExpanded) await expand(currentSectionId);
        y = await addElement(contactsSection, pdf, y, horizontalMargin, verticalMargin, verticalSpacing);
        if (!isExpanded) collapse(currentSectionId);

      }
      setDoneCount(operationsDoneCounter++);
    }

    const d = new Date();
    const dateString = d.getFullYear() +
      (d.getMonth() + 1).toString().padStart(2, '0') +
      d.getDate().toString().padStart(2, '0') +
      '_' +
      d.getHours().toString().padStart(2, '0') +
      d.getMinutes().toString().padStart(2, '0');

    pdf.save('rewardshub_' + customerCode + '_' + dateString + '.pdf');

    setPrintMode(false);

    document.body.classList.remove('print');

    releaseScreenLock();
  }

  function loadData(retry = true) {
    const customerID = parseInt(customerId || '0', 10);
    const typeID = parseInt(typeId || '0', 10);
    let tempCustomerCode = '';

    if (customerID > 0 && typeID > 0) {
      setIsLoading(true);
      dispatch(loaderSlice.actions.startLoading());
      apiCustomer({ customerId: customerID, isInternal: typeID === 1 })
        .then(response => {
          // Fix rayban brand name
          (response.data.data.customer_data?.brands ?? []).forEach((brand: any) => {
            if (brand.brand_name.toUpperCase() === 'RAYBAN' ||
              brand.brand_name.toUpperCase() === 'RAY BAN' ||
              brand.brand_name.toUpperCase() === 'RAY-BAN')
              brand.brand_name = 'RAY-BAN';

            if (brand.brand_name.toUpperCase() === 'OAKLEY')
              brand.brand_name = 'OAKLEY & COSTA';
          });

          setCustomer(response.data.data);
          tempCustomerCode = response.data.data?.customer_data?.customer_code ?? '';
          if (response.data?.data?.customer_data.common_owner_code === null) {
            setIsMainAccount(true);
          }
          setIsXR((response.data.data?.customer_data?.alliance_code ?? '').toLowerCase().startsWith('xr'));
          setLevel(response.data.data?.customer_data?.customer_status_level?.level_status_description);
          setBrandTrackingData(response.data.data?.vs_brand_learnMore);

          const tempBrands = response.data.data?.customer_data.brands || [];
          const tempBrandsArray = Array<any>();
          const brandIDsArray = Array<any>();
          tempBrands.forEach((brand: any) => {
            tempBrandsArray.push(brand);
            brandIDsArray.push(brand.brand_id);
          });
          setBrandsArray(tempBrandsArray);
          setPdfItemsBrands(brandIDsArray);

          if (!(response.data.data?.customer_data?.alliance_code ?? '').toLowerCase().startsWith('xr')) {
            removePdfItem('vsdata');
          }

          setIsLoading(false);
          dispatch(loaderSlice.actions.stopLoading());
        })
        .then(() => {
          if (tempCustomerCode !== '') {
            apiRebateFromCustomer({ customerCode: tempCustomerCode }).then(response => {
              setPerformances(response.data.data);
            });
          }
        }).then(() => {
          if (tempCustomerCode !== '') {
            apiTypeIDFromCustomer({ customerCode: tempCustomerCode }).then(response => {
              // Fix values
              response.data?.data?.forEach((item: any) => {
                item.value = item.value !== null && item.value !== undefined && item.value !== '' ? parseFloat(item.value) : null;
              });
              // setTypeIDs(response.data.data);
            });
          }
        }).then(() => {
          if (tempCustomerCode !== '') {
            apiRebatesPotential({ customerCode: tempCustomerCode }).then(response => {
              setPotential(response.data.data);
            });
          }
        })
        /*
        .then(() => {
          if (customerCode !== '') {
            apiVisionSourceRebateData({ customerCode: customerCode }).then(response => {
              setVSTypeIDs(response.data.data);
            });
          }
        })
        */
        .then(() => {
          if (tempCustomerCode !== '') {
            apiVisionSourceSections({ customerCode: tempCustomerCode }).then(response => {
              setVSSections(response.data.data);
            });
          }
        })
        .catch(error => {
          if (error.response && error.response.status && error.response.status === 401 && refreshToken) {
            apiRefresh({ refreshToken })
              .then(response => {
                if (response.data?.data?.jwt_token && response.data?.data?.refresh_token) {
                  localStorage.setItem('jwtToken', response.data.data.jwt_token);
                  localStorage.setItem('refreshToken', response.data.data.refresh_token);
                  if (retry) {
                    loadData(false);
                  }
                }
              })
              .catch(() => {
                clearStorage();
                navigate(`${ROUTE_HOME}`, { replace: true });
              });
          } else {
            clearStorage();
            navigate(`${ROUTE_HOME}`, { replace: true });
          }
        });
    } else {
      navigate(`${ROUTE_SEARCH}`, { replace: true });
    }
  }

  function checkCustomer() {
    const currentCustomerId = localStorage.getItem('customerId');
    const currentRole = localStorage.getItem('role');
    if (
      currentCustomerId !== null &&
      currentRole !== null &&
      currentCustomerId !== 'null' &&
      currentRole !== 'null' &&
      currentCustomerId !== customerId
    )
      return true;
    return false;
  }

  useEffect(() => {
    document.title = 'Dashboard | Rewards Hub';
    window.scrollTo(0, 0);
    if (checkCustomer()) {
      // const currentCustomerId = localStorage.getItem('customerId');
      // const currentCustomerTypeId = localStorage.getItem('typeId');
      // navigate(`${ ROUTE_DASHBOARD } / ${ currentCustomerId } / ${ currentCustomerTypeId }`, { replace: true });
    } else {
      loadData();
    }
  }, []);

  const goTo = (target: string) => {
    if (target === 'contacts') setContactsOpened(true);
    if (target === 'doors') setDoorsOpened(true);
    const element = document.getElementById(target);
    if (element) {
      const dims = element.getBoundingClientRect();
      window.scrollTo(window.scrollX, dims.top - 100);
    }
    return false;
  };

  /*
  useEffect(() => {
    setId(parseInt(id, 10));
    setRole(initialStateRole);
  }, [id, initialStateRole]);
  */

  function computeDonePercentage(doneCount: number) {
    const temp = Math.ceil((doneCount * 100) / countTotalOperations());
    if (temp > 100) return 100;
    return temp;
  }

  return (
    <>
      {/*
      <Navbar className="nav-bar">
        <NavbarSerp>
          <Logo>
            <a href={`${ ROUTE_SEARCH }`}>
              <UIIcon name={'Logo'} srcIcon={srcLogo} width={''} height={'54px'} className="" />
            </a>
          </Logo>
          {!isCustomer && <SearchInput isSerp isNavigate />}
          <Accordion />
        </NavbarSerp>
        <Alert customer={customer} variant={'Danger'} />
      </Navbar>
  */}
      <MainHeader
        showUser={true}
        enableLeftMenu={true}
        showNavigationBar={true}
        showPdfExportButton={true}
        showSearchBar={false}
        showSearchButton={true}
        customer={customer} />

      <section className="main">
        <div id="main-content" className="container dashboard">

          {!isLoading && (
            <>
              {/* GeneralInfo (Small) */}
              <GeneralInfoSmall customer={customer} doorsAction={() => goTo('doors')} /* contactsAction={() => goTo('contacts')} */ />

              {/* GeneralInfo */}
              <GeneralInfo customer={customer} doorsAction={() => goTo('doors')} /* contactsAction={() => goTo('contacts')} */ />

              {/* <div className="mb5">
                <button onClick={downloadPdfDocument}>Print</button>
              </div> */}

              {/* Credit */}
              {!isCustomer &&
                <Credit customer={customer} printMode={printMode} />
              }

              {/* DataInfo */}
              <DataInfo customer={customer} />

              {/* TotalProvided 
              <TotalProvided customer={customer} performances={performances} />*/}

              {/* Savings */}
              <Savings customer={customer} />

              {/* RewardsRebates */}
              <RewardsRebates customer={customer} potential={potential} isXR={isXR} />

              {/* Rebates */}
              {false && isMainAccount &&
                <Rebates customer={customer} />}

              {/* VSData */}
              {isXR &&
                <VSData customer={customer} performances={performances} sections={vsSections} brandTrackingData={brandTrackingData} />}

              {/* VSDataFooter */}
              {false &&
                <VSDataFooter customer={customer} />}

              {/* TotalPortFolioRebate */}
              {!isXR && customerCode !== '0010015790' && customerCode !== '0010023230' &&
                (level.toLowerCase().includes('platinum') || level.toLowerCase().includes('diamond')
                  || level.toLowerCase().includes('multi') || level.toLowerCase().includes('plus')) &&
                <TotalPortFolioRebate customer={customer} performances={performances} dateInTitle={false} />}

              {/* Brand */}
              {false && performances !== null && !isXR && customerCode !== '0010015790' && customerCode !== '0010023230' &&
                <Brand key={'brand_performance'} isXR={isXR} customer={customer} performances={performances} lockUpdates={pdfWorkaround} />}

              {/* BrandPerformance */}
              <BrandPerformance customer={customer} />

              {/* GPNSales */}
              {false && !isCustomer &&
                <GPNSales customer={customer} />}

              {/* EL360 */}
              <EL360 customer={customer} printMode={printMode} />

              {/* Doors */}
              <Doors customer={customer} opened={doorsOpened} printMode={printMode} />

              {/* Contact */}
              <Contact customer={customer} opened={contactsOpened} printMode={printMode} />
              {lastUpdatePanelEnabled && <div id='last-update'>{lastUpdateDate !== null && `Last data update: ${lastUpdateDate}`}</div>}

              <PrintInfo printDate={printDate} lastUpdateDate={lastUpdateDate} />

              <section id="cover" className="main">
                <div className="container search">
                  <UIIcon name={'Logo'} srcIcon={srcLogo} width={''} height={''} className="logo" />
                  <div className="claim-intro">
                    The Brands your patients want, <span>with a profitability opportunity your practice needs!</span>
                  </div>
                  <div className="claim">Start Earning Today!</div>
                  <div className="cover-title">Rebate &amp; Savings</div>
                </div>
              </section>

              <GeneralInfoCover customer={customer} doorsAction={() => { }} /* contactsAction={() => goTo('contacts')} */ />

              {false && performances !== null && customerCode !== '0010015790' && customerCode !== '0010023230' &&
                <>
                  {pdfItemsBrands.map((element) => <BrandPrintable key={'pdfItemBrand_' + element} isXR={isXR} customer={customer} performances={performances} brandId={element} />)}
                </>}
            </>
          )}
        </div>
      </section>

      {pdfFakeCounter > 0 &&
        <div className="modal fade modal-lg" id="pdf-export-options"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          aria-labelledby="pdf-export-options-label"
          aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="pdf-export-options-label">Export to PDF</h5>
                <button id="pdf-export-close-button" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
                <a onClick={() => { togglePdfItem('account-information'); }} className={pdfItems.includes('account-information') ? 'selected' : ''}>Account information</a>
                <a onClick={() => { togglePdfItem('credit-status'); }} className={pdfItems.includes('credit-status') ? 'selected' : ''}>Credit status</a>
                <a onClick={() => { togglePdfItem('portfolio-rating'); }} className={pdfItems.includes('portfolio-rating') ? 'selected' : ''}>Total Portfolio Summary</a>
                <a onClick={() => { togglePdfItemSavings(new Date().getFullYear() - 1); }} className={pdfItemsSavings.includes(new Date().getFullYear() - 1) ? 'selected' : ''}>Savings {new Date().getFullYear() - 1}</a>
                <a onClick={() => { togglePdfItemSavings(new Date().getFullYear()); }} className={pdfItemsSavings.includes(new Date().getFullYear()) ? 'selected' : ''}>Savings {new Date().getFullYear()}</a>
                <a onClick={() => { togglePdfItemRebates(new Date().getFullYear() - 1); }} className={pdfItemsRebates.includes(new Date().getFullYear() - 1) ? 'selected' : ''}>Rebates {new Date().getFullYear() - 1}</a>
                <a onClick={() => { togglePdfItemRebates(new Date().getFullYear()); }} className={pdfItemsRebates.includes(new Date().getFullYear()) ? 'selected' : ''}>Rebates {new Date().getFullYear()}</a>
                {!isXR && customerCode !== '0010015790' && customerCode !== '0010023230' &&
                  (level.toLowerCase().includes('platinum') || level.toLowerCase().includes('diamond')
                    || level.toLowerCase().includes('multi') || level.toLowerCase().includes('plus')) &&
                  <a onClick={() => { togglePdfItem('total-portfolio-rebates'); }} className={pdfItems.includes('total-portfolio-rebates') ? 'selected' : ''}>Portfolio Rebates</a>}
                {isXR &&
                  <a onClick={() => { togglePdfItem('vsdata'); }} className={pdfItems.includes('vsdata') ? 'selected' : ''}>Vision Source Elite Vendor</a>}
                {false && performances !== null && !isXR && customerCode !== '0010015790' && customerCode !== '0010023230' &&
                  <Selection<Array<number>>
                    key={'brands-selection'}
                    componentKey={'brands-selection'}
                    id="brands-selection"
                    cssClass={pdfItemsBrands.length > 0 ? 'selected' : ''}
                    name="brands-selection"
                    disabled={false}
                    map={brandsArray}
                    placeholder='Brand Pillar Rebates'
                    displayExpr='brand_name'
                    valueExpr='brand_id'
                    selectionMode={SelectionMode.multiple}
                    values={pdfItemsBrands}
                    onChangedSelection={(selection: any) => {
                      setPdfWorkaround(true);
                      setPdfItemsBrands(selection ?? null);
                      setTimeout(() => { setPdfWorkaround(false); }, 1000);
                    }} />}
                <a onClick={() => { togglePdfItem('el360'); }} className={pdfItems.includes('el360') ? 'selected' : ''}>Brand Performance</a>
                <a onClick={() => { togglePdfItem('el360panel'); }} className={pdfItems.includes('el360panel') ? 'selected' : ''}>EL360 Benefits</a>
                <a onClick={() => { togglePdfItem('doors'); }} className={pdfItems.includes('doors') ? 'selected' : ''}>Doors</a>
                <a onClick={() => { togglePdfItem('contacts'); }} className={pdfItems.includes('contacts') ? 'selected' : ''}>Contact</a>
                <div className='export-container'>
                  <button className={pdfExportEnabled ? 'export-button' : 'export-button disabled'} onClick={() => {
                    if (pdfExportEnabled) {
                      document.getElementById('pdf-export-close-button')?.dispatchEvent(new MouseEvent('click', {
                        'view': window,
                        'bubbles': true,
                        'cancelable': false,
                      }));
                      setTimeout(() => { toPdf(); }, 500);
                    }
                  }}>Export</button>
                </div>
              </div>
            </div>
          </div>
        </div>}
      <div id="pdf-loader"><Loader show={true} message={'Generating PDF...' + computeDonePercentage(doneCount) + '%'} /></div>
    </>
  );
};