/** @format */

/* eslint-disable */

import { FC, useState } from 'react';
import { UIIcon } from '../../../../shared/Icon';
import { UILabel } from '../../../../shared/Label';
import userIcon2 from '../../../../assets/icons/outline/2.svg';
import userIcon1 from '../../../../assets/icons/outline/1.svg';
import mailIcon from '../../../../assets/images/v2/mail.svg';
import pinIcon from '../../../../assets/images/v2/pin.svg';
import doorsIcon from '../../../../assets/images/v2/doors.svg';
import iconSilver from '../../../../assets/images/v2/silver-simple.svg';
import iconGold from '../../../../assets/images/v2/gold-simple.svg';
import iconPlatinum from '../../../../assets/images/v2/platinum-simple.svg';
import iconDiamond from '../../../../assets/images/v2/diamond-simple.svg';
import iconMultiDiamond from '../../../../assets/images/v2/multidiamond-simple.svg';
import { MESSAGE_NOT_AVAILABLE_ABBREV } from '../../../../constants/general';
import { UITooltip } from '../../../../shared/Tooltip';
import { InfoEL360 } from './InfoEL360';

interface Props {
  email: string;
  name: string;
  address: string;
  state: string;
  commonOwnerCode: string;
  allianceCode: string;
  allianceName: string;
  el360Status: string;
  customerCode: string;
  level: string;
  levelRating: string;
  activeDoorsNumber: number;
  doorsAction: () => void;
}

export const InfoCard: FC<Props> = ({
  email,
  name,
  address,
  state,
  commonOwnerCode,
  allianceCode,
  allianceName,
  el360Status,
  customerCode,
  level,
  levelRating,
  activeDoorsNumber,
  doorsAction
}): JSX.Element => {
  const isCommonOwner = commonOwnerCode !== null && commonOwnerCode.length > 0;
  const [isTooltip, setIsTooltip] = useState(false);

  let levelIcon = '';
  let levelString = '';

  if (levelRating && levelRating.length > 0) {
    if (levelRating.toLowerCase() === 'a') {
      levelIcon = iconDiamond;
      levelString = 'Diamond';
    } else if (levelRating.toLowerCase() === 'c') {
      levelIcon = iconGold;
      levelString = 'Gold';
    } else if (levelRating.toLowerCase() === 'b') {
      levelIcon = iconPlatinum;
      levelString = 'Platinum';
    } else if (levelRating.toLowerCase() === 'd') {
      levelIcon = iconSilver;
      levelString = 'Silver';
    } else {
      levelIcon = '';
      levelString = '';
    }
  } else {
    if (level.toLowerCase().includes('silver')) {
      levelIcon = iconSilver;
      levelString = 'Silver';
    } else if (level.toLowerCase().includes('gold')) {
      levelIcon = iconGold;
      levelString = 'Gold';
    } else if (level.toLowerCase().includes('platinum')) {
      levelIcon = iconPlatinum;
      levelString = 'Platinum';
    } else if (level.toLowerCase().includes('multi') || level.toLowerCase().includes('plus')) {
      levelIcon = iconMultiDiamond;
      levelString = 'Diamond Plus';
    } else if (level.toLowerCase().includes('diamond')) {
      levelIcon = iconDiamond;
      levelString = 'Diamond';
    }
  }



  return (
    <div className="info">
      <div className="icon" onMouseEnter={() => setIsTooltip(false)} onMouseLeave={() => setIsTooltip(false)}>
        {isCommonOwner ? (
          <UIIcon name={'user-icon'} srcIcon={userIcon1} width={'26'} height={'26'} className="" />
        ) : (
          <UIIcon name={'user-icon'} srcIcon={userIcon2} width={'26'} height={'26'} className="" />
        )}
        <div
          style={{
            position: 'absolute',
            marginBottom: '135px',
          }}
        >
          <UITooltip label={isCommonOwner ? 'Common Owner' : 'Main Account'} isVisible={isTooltip} />
        </div>
      </div>

      <div className="customer">
        <div className='name'>{name} ({customerCode})</div>
        <div className='address'>
          <UIIcon name={'position'} srcIcon={pinIcon} width={'10'} height={'10'} className="" />
          <div className='text'>{address?.length > 0 ? address : MESSAGE_NOT_AVAILABLE_ABBREV}</div>
        </div>
        <div className='email'>
          <UIIcon name={'email'} srcIcon={mailIcon} width={'10'} height={'10'} className="" />
          <div className='text'>{email?.length > 0 ? email : MESSAGE_NOT_AVAILABLE_ABBREV}</div>
        </div>
        <div className='customer-box'>
          {!isCommonOwner && customerCode && customerCode.length > 0 && (
            <div className='customer'>
              <UILabel
                color={'#56a36a'}
                name={'Customer: ' + (customerCode ?? MESSAGE_NOT_AVAILABLE_ABBREV)}
                backgroundColor={'#d1e7d7'}
                borderColor={'#d1e7d7'}
              />
            </div>
          )}
          {commonOwnerCode && commonOwnerCode.length > 0 && (
            <div className='customer'>
              <UILabel
                color={'#091f5a'}
                name={'CO: ' + (commonOwnerCode ?? MESSAGE_NOT_AVAILABLE_ABBREV)}
                backgroundColor={'#bdcbfc'}
                borderColor={'#bdcbfc'}
              />
            </div>
          )}
          {allianceCode && allianceCode.trim().length > 0 && (
            <div className='customer'>
              <UILabel
                color={'#c760e0'}
                name={
                  allianceName && allianceName.trim().length > 0
                    ? 'Alliance: ' + allianceName + ' (' + (allianceCode.trim() ?? MESSAGE_NOT_AVAILABLE_ABBREV) + ')'
                    : 'Alliance: ' + (allianceCode.trim() ?? MESSAGE_NOT_AVAILABLE_ABBREV)
                }
                backgroundColor={'#f8eafb'}
                borderColor={'#f8eafb'}
              />
            </div>
          )}
        </div>




        {/*
        <Row>
          
          {levelIcon !== '' && levelString !== '' && (
            <div className='level'>
              <TierIcon name={'icon'} srcIcon={levelIcon} width={'50'} height={'50'} className="tier-icon" />
              <div className='text'>{levelString}</div>
            </div>
          )}
        </Row>
        */}
      </div>
      <div className='infos'>
        <InfoEL360 el360_status={el360Status} />
        {levelString &&
          <>
        <div className='info-box level'>
          <div className="icon">
            <UIIcon name={'level-icon'} srcIcon={levelIcon} width={'20'} height={'20'} className="" />
          </div>
          <span className="title">{levelString}</span>
        </div>
        <div className='info-box-separator' /></>}
        <div className='info-box doors' onClick={() => doorsAction()}>
          <UIIcon name={'doors-icon'} srcIcon={doorsIcon} width={'27.5'} height={'25'} className="" />
          <span className="title">Active Doors</span>
          <span className="value">{activeDoorsNumber}</span>
        </div>
      </div>
    </div>
  );
};
