/** @format */

import { FC, useState } from 'react';
import { UIIcon } from '../../../shared/Icon';
import { UILabel } from '../../../shared/Label';
import userIcon2 from '../../../assets/icons/outline/2.svg';
import userIcon1 from '../../../assets/icons/outline/1.svg';
import mailIcon from '../../../assets/images/v2/mail.svg';
import pinIcon from '../../../assets/images/v2/pin.svg';
import { ROUTE_DASHBOARD } from '../../../constants/general';
import { UITooltip } from '../../../shared/Tooltip';
import { Fade } from 'react-awesome-reveal';
//#region ::: PARTIALS

/*
const Container = styled(Link)({
  textDecoration: 'none',
  border: `1px solid ${mapColors.backgrounds.borderPrimary}`,
  backgroundColor: 'white',
  borderRadius: '10px',
  display: 'flex',
  alignItems: 'flex-start',
  marginBottom: '25px',
  width: '900px',
  '&:hover': {
    border: `1px solid ${mapColors.theme.primary[0]}`,
    boxShadow: '0px 10px 40px -8px #aeaeae',
    cursor: 'pointer',
  },
  '@media (max-width: 960px)': {
    width: '700px',
  },

  '@media (max-width: 768px)': {
    width: '500px',
  },

  '@media (max-width: 600px)': {
    width: '400px',
  },

  '@media (max-width: 390px)': {
    width: '350px',
  },
});
*/

//#endregion

interface Props {
  customerId: number;
  customerCode: string;
  email: string;
  name: string;
  address: string;
  state: string;
  allianceCode: string;
  ownerCode: string;
  mainAccountCode: string;
  type: 1 | 2;
  tooltipLabel: string;
}

export const Card: FC<Props> = ({
  customerId,
  customerCode,
  email,
  name,
  address,
  state,
  allianceCode,
  mainAccountCode,
  ownerCode,
  type,
}): JSX.Element => {
  const [isTooltip, setIsTooltip] = useState(false);
  const isCommonOwner = ownerCode !== null && ownerCode.length > 0;

  return (
    <div className="item">
      <Fade>
        <a href={`${ROUTE_DASHBOARD}/${customerId}/${type}`}>

          <div className="icon" onMouseEnter={() => setIsTooltip(true)} onMouseLeave={() => setIsTooltip(false)}>
            {isCommonOwner ? (
              <UIIcon name={'user-icon'} srcIcon={userIcon1} width={'26'} height={'26'} className="" />
            ) : (
              <UIIcon name={'user-icon'} srcIcon={userIcon2} width={'26'} height={'26'} className="" />
            )}
            <div
              style={{
                position: 'absolute',
                marginBottom: '120px',
              }}
            >
              <UITooltip label={isCommonOwner ? 'Common Owner' : 'Main Account'} isVisible={isTooltip} />
            </div>
          </div>

          <div className="detail">
            {email ? (
              <div className='email'>
                <UIIcon name={'email'} srcIcon={mailIcon} width={'12'} height={'12'} className="" />
                <div className='text'>{email}</div>
              </div>
            ) : (
                /*<div style={{ marginTop: '20px' }} />*/
                <></>
            )}

            <div className="name" style={{ marginBottom: ownerCode && allianceCode ? '5px' : 0 }}>{name}</div>

            <div className="customer">
              {customerCode && (
                <UILabel
                  color={'#56a36a'}
                  name={'Customer: ' + customerCode}
                  backgroundColor={'#d1e7d7'}
                  borderColor={'#d1e7d7'}
                />
              )}

              {ownerCode && (
                <UILabel
                  color={'#091f5a'}
                  name={'CO: ' + ownerCode}
                  backgroundColor={'#bdcbfc'}
                  borderColor={'#bdcbfc'}
                />
              )}

              {!isCommonOwner && (
                <UILabel
                  color={'#091f5a'}
                  name={'Main Account: ' + mainAccountCode}
                  backgroundColor={'#bdcbfc'}
                  borderColor={'#bdcbfc'}
                />
              )}

              {allianceCode && allianceCode.trim().length > 0 && (
                <UILabel
                  color={'#c760e0'}
                  name={`Alliance Code: ${allianceCode.trim()}`}
                  backgroundColor={'#f8eafb'}
                  borderColor={'#f8eafb'}
                />
              )}
            </div>

            <div className="address" style={{ marginTop: ownerCode && allianceCode ? '25' : 0 }}>
              <UIIcon name={'position'} srcIcon={pinIcon} width={'14'} height={'14'} className="" />
              <div className='text'>{address} {state}</div>
            </div>
          </div>
        </a>
      </Fade>
    </div>
  );
};
